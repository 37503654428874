.preload-loading {
  z-index: 10001;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.preload-full-container {
  min-width: 320px;
  z-index: 10000;
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  display: flex;
}
.preload-sidebar {
  width: 60px;
  height: 100vh;
  background-color: #0a132a;
}
.preload-sidebar-background {
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 5px;
  padding-right: 45px;
  margin-top: 20px;
  width: 40px;
  height: 40px;
  background-color: #0a132a;
  border-left: solid 5px;
}
.preload-sidebar-icon {
  width: 30px;
  height: 30px;
  margin: 15px;
  margin-bottom: 20px;
  border-radius: 50%;
  background-color: #3c4b71;
}
.preload-sidebar-background > .preload-sidebar-icon {
  margin: 0;
  background-color: #1577fe;
}
.preload-sidebar-icon-top {
  background-image: url(http://localhost:8081/zebu_web/static/src/img/z.png);
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: 0 2px;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-top: 0;
  margin-bottom: 15px;
  border: solid 0 #fff;
}
.blue {
  border-color: #1577fe;
}
.preload-sub-sidebar {
  width: 250px;
  height: 100vh;
  background-color: #020819;
}
.preload-profile {
  margin-top: 2%;
  display: flex;
  height: 55px;
  width: 250px;
}
.preload-profile-image {
  height: 42px;
  width: 42px;
  margin: 11px;
  border-radius: 50%;
  background-color: #0a132a;
}
.preload-profile-status {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #35c849;
  border-radius: 50%;
  top: 37px;
  left: 97px;
  border: solid 5px #020819;
}
.preload-adjacent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.preload-name {
  height: 15px;
  width: 120px;
  border-radius: 8px;
  background-color: #3c4b71;
}
.preload-handle {
  height: 10px;
  width: 100px;
  border-radius: 5px;
  background-color: #3c4b71;
}
.preload-sub-sidebar {
  width: 250px;
  height: 100vh;
  background-color: #020819;
}
.preload-sub-sidebar-container {
  width: 100%;
  height: 100%;
  background-color: #020819;
}
.preload-tab {
  height: 48px;
  width: 40%;
  margin-left: 10px;
  border-bottom: solid 5px #1577fe;
}
.preload-search {
  position: relative;
  top: 10px;
  height: 37px;
  width: calc(100% - 20px);
  margin: 10px 10px;
  background-color: #0a132a;
  border-radius: 5px;
}
.preload-team-name {
  width: 50%;
  height: 15px;
  border-radius: 5px;
  background-color: #0a132a;
  margin: auto;
  margin-left: 10px;
  margin-top: 20px;
}
.preload-keyline {
  height: 10px;
  width: calc(100% - 10px);
  margin-left: 5px;
  border-bottom: solid 1px #151f32;
  margin-bottom: 10px;
}
.preload-chat {
  display: flex;
  flex-direction: row;
  align-content: center;
}
.preload-chat-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0a132a;
  margin: 10px;
}
.preload-chat-name {
  width: 50%;
  height: 10px;
  border-radius: 5px;
  background-color: #3c4b71;
  margin: auto;
  margin-left: 10px;
}
.preload-top {
  display: flex;
  padding-top: 10px;
  padding-bottom: 9%;
  width: calc(100vw - calc(250px + 60px));
  height: 54px;
  background-color: #fff;
  border-bottom: solid 3px #f3f3f3;
}
.preload-top-search {
  height: 34px;
  margin-top: 5px;
  width: 300px;
  border: solid 3px #f3f3f3;
  margin-left: auto;
  margin-right: 10px;
}
.preload-channel-details {
  height: 129px;
  width: 100%;
}
.preload-channel-detail-head {
  display: flex;
  width: 100%;
  height: 60px;
  margin-top: 30px;
  margin-left: 30px;
}
.preload-channel-image {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 50%;
}
.preload-channel-name {
  width: 100px;
  height: 15px;
  margin-left: 20px;
  margin-top: 10px;
  background-color: #eee;
  border-radius: 8px;
}
.preload-channel-description {
  width: 200px;
  height: 8px;
  margin-left: 20px;
  margin-top: 10px;
  background-color: #eee;
  border-radius: 8px;
}
.preload-channel-detail-welcome {
  width: 100%;
  height: 60px;
  margin-left: 20px;
  margin-top: 10px;
  opacity: 0.5;
  border-bottom: solid 3px #eee;
}
.preload-welcome-title {
  width: 90%;
  height: 20px;
  border-radius: 10px;
  background-color: #e3e3e3;
}
.preload-welcome-start {
  width: 80%;
  height: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #eee;
}
.preload-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  height: 100vh;
  width: 100%;
}
.preload-card {
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 70px;
}
.preload-card-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-right: 20px;
}
.preload-card-content {
  display: flex;
  width: 90%;
  flex-direction: column;
}
.preload-card-header {
  display: flex;
  flex-direction: row;
}
.preload-card-name {
  width: 100px;
  height: 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
}
.preload-card-time {
  width: 50px;
  height: 10px;
  margin-top: 5px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin-left: 10px;
}
.preload-card-msg {
  margin-top: 10px;
  background-color: #f5f5f5;
  height: 30px;
  width: 25%;
  border-radius: 5px;
}
.preload-long {
  width: 50% !important;
}
.preload-short {
  width: 15% !important;
}
.preload-tall {
  width: 50% !important;
  height: 100px !important;
}
.preload-composer {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  position: absolute;
  left: 310px;
  bottom: 0;
  height: 50px;
  width: calc(100vw - 310px);
  background-color: #fff;
  border-top: solid 3px #f3f3f3;
}
.preload-composer-button {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 20px;
  background-color: #808285;
  border-radius: 50%;
}
.preload-composer-text {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 200px;
  border-radius: 10px;
  background-color: #9e9d9e;
}
.preload-composer-start {
  margin-left: 10px;
  margin-right: 10px;
  justify-self: start !important;
}
.preload-compsoer-last {
  margin-left: 10px;
  margin-right: 10px;
}
.preload-composer-end {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  justify-self: end;
}
.preload-card-first {
  margin-top: 30px;
}
.preload-load {
  animation: loady 4s linear 0s infinite;
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  background-position: 0 0;
  background-size: 200px 200px;
  background-repeat: repeat-y;
}
@keyframes loady {
  from {
    background-position: -500px 0;
  }
  to {
    background-position: 2000px 0;
  }
}
@keyframes loadx {
  from {
    background-position: 0 -500px;
  }
  to {
    background-position: 0 1000px;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .preload-sidebar {
    width: 0;
    opacity: 0;
  }
  .preload-sub-sidebar {
    width: 0;
    opacity: 0;
  }
  .preload-top {
    background-color: #2e2f34;
    width: 100vw;
    height: 107px;
    padding-bottom: 9%;
  }
  .preload-main {
    width: 100vw;
  }
  .preload-composer {
    left: 0 !important;
    margin-top: auto;
    align-self: flex-end;
    width: 100vw !important;
  }
  .preload-card {
    margin-left: 10px;
    width: calc(100vw - 10px);
  }
  .preload-long {
    width: 80% !important;
  }
  .preload-tall {
    width: 80% !important;
  }
  .preload-short {
    width: 25% !important;
  }
  .preload-card-msg {
    width: 50%;
  }
  .preload-top-search {
    display: none;
  }
  .preload-mobile-hide {
    display: none;
  }
  .preload-load {
    animation-name: loadx;
    animation-duration: 2s;
    background-image: linear-gradient(0deg, transparent, #fff, transparent);
    background-repeat: repeat-x;
  }
}
