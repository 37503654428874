body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

button { font-family: 'Montserrat', sans-serif;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
